<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>
            <h5>Chi Siamo</h5>
          </strong>
        </CCardHeader>
        <br />
      </CCol>
    </CRow>

    <div>
      <UploadMultiplePhotoAndPreview
        @getPhoto="
          (data) => {
            this.foto_azienda = data;
          }
        "
        :img="foto_azienda"
        :aspectRatioVariableProps="aspectRatioVariableProps"
        title="Chi siamo"
      />
    </div>

    <!-- SECTION CHOOSE TEXTURE -->
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Testo Descrizione</strong>
        </CCardHeader>
        <CCard class="customCard">
          <textarea v-model="chi_siamo" rows="20" cols="100"></textarea>
        </CCard>
      </CCol>
    </CRow>
    <!-- SECTION END CHOOSE TEMPLATE -->

    <!-- SECTION CHOOSE TEXTURE -->
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Servizi</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="3" v-for="item in servizi" :key="item.id">
                <CInputCheckbox
                  :checked="checkIfChecked(item)"
                  :label="item.nome"
                  @change="checkService(item, $event)"
                />
                <br />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <div class="custom-button-manifesto-extra-space">
      <CButton v-on:click="save()" type="submit" color="info">
        <CIcon name="cil-save" />
        Salva
      </CButton>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { Agenzia } from '@/services/api/agenzia';
import UploadMultiplePhotoAndPreview from '@/views/funer24/components/UploadMultiplePhotoAndPreview';
import { mapActions } from 'vuex';

export default {
  name: 'Chisiamo',
  components: {
    UploadMultiplePhotoAndPreview,
  },

  data: function () {
    return {
      servizi: [],
      servizi_scelti: [],
      foto_azienda: null,
      chi_siamo: null,
      aspectRatioVariableProps: 1.78,
    };
  },
  methods: {
    ...mapActions('agenzia', ['update']),

    checkIfChecked(item) {
      for (let i = 0; i < this.servizi_scelti.length; i++) {
        if (this.servizi_scelti[i].id === item.id) {
          return true;
        }
      }
      return false;
    },

    checkService(item, event) {
      if (event.target.checked) {
        this.servizi_scelti.push(item);
      } else {
        for (let i = 0; i < this.servizi_scelti.length; i++) {
          if (this.servizi_scelti[i].id === item.id) {
            this.servizi_scelti.splice(i, 1);
          }
        }
      }
    },

    async getData() {
      const req = [Agenzia.sottoservizi(), Agenzia.get()];

      await Promise.all(req)
        .then((values) => {
          return [values[0].data.results, values[1].data.results[0]];
        })
        .then((res) => {
          let agenzia = res[1];
          this.servizi = res[0];

          this.id = agenzia.id;
          this.chi_siamo = agenzia.chi_siamo;
          this.foto_azienda = agenzia.foto_azienda;

          if (agenzia.servizi_scelti !== null) this.servizi_scelti = agenzia.servizi_scelti;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    save() {
      this.update({
        servizi_scelti: this.servizi_scelti,
        chi_siamo: this.chi_siamo,
        foto_azienda: this.foto_azienda,
      }).then((res) => {
        if (res) this.$router.push('/dashboard');
      });
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
td.custom-td-services {
  display: flex;
}

table.table.custom-services {
  font-size: 15px;
}
</style>
